import React, { useRef } from 'react';
import styles from './PinInput.module.scss';

interface PinInputProps {
	value: string;
	onChange: (value: string) => void;
}

export const PinInput: React.FC<PinInputProps> = ({ value, onChange }) => {
	const inputsRef = useRef<HTMLInputElement[]>([]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const newValue = e.target.value;
		if (/^\d$/.test(newValue)) {
			const newCode = value.split('');
			newCode[index] = newValue;
			onChange(newCode.join(''));

			if (index < inputsRef.current.length - 1) {
				inputsRef.current[index + 1].focus();
			}
		} else if (newValue === '') {
			const newCode = value.split('');
			newCode[index] = '';
			onChange(newCode.join(''));

			if (index > 0) {
				inputsRef.current[index - 1].focus();
			}
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData('text');
		// Only handle numbers and ensure it fits the length of the PIN
		const filteredPasteData = pasteData.replace(/\D/g, '').slice(0, inputsRef.current.length);
		onChange(filteredPasteData.padEnd(inputsRef.current.length, ''));
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && value[index] === '') {
			if (index > 0) {
				// Move to the previous input if current input is empty and Backspace is pressed
				inputsRef.current[index - 1].focus();
			}
		}
	};

	return (
		<div className={styles.pinInputContainer}>
			{[...Array(6)].map((_, index) => (
				<input
					key={index}
					type="text"
					inputMode="numeric"
					pattern="[0-9]*"
					value={value[index] || ''}
					onChange={(e) => handleChange(e, index)}
					onPaste={handlePaste}
					onKeyDown={(e) => handleKeyDown(e, index)}
					maxLength={1}
					ref={(el) => el && (inputsRef.current[index] = el)}
					className={styles.pinInput}
				/>
			))}
		</div>
	);
};
