import React, { useEffect, useState } from 'react';
import 'swiper/css';
import styles from './Slider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';


export const Slider: React.FC = () => {
  const [banners, setBanners] = useState<string[]>(["./1.jpg", "./2.jpg","./3.jpg","./4.jpg",]);


  return (
    <Swiper
      className={styles.swiper}
      spaceBetween={50}
      slidesPerView={1}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={index} className={styles.slide}>
           <img src={banner} alt={`slide-${index}`}/>
            </SwiperSlide>
            ))}
        </Swiper>
      );
      };