import styles from './Header.module.scss'
import logo from '../../assets/icons/logo.png'

export const Header = () => {
  return(
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          Совет ассоциаций
          {/*<img src={logo} alt="logo"/>*/}
        </div>
      </header>
    </>
  )
}