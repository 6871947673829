import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilters } from "./user.type";

interface UsersState {
  storeSearch: string;
  storeCompany: IFilters[];
}

const initialState: UsersState = {
  storeSearch: '',
  storeCompany: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.storeSearch = action.payload;
    },
    setCompany: (state, action: PayloadAction<IFilters[]>) => {
      state.storeCompany = action.payload;
    },
  },
});

export const users = usersSlice.reducer;
export const usersActions = usersSlice.actions;
