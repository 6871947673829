import styles from './UserList.module.scss';
import { IFilters, IFiltersAll, IUser } from "../../app/store/users/user.type";
import { FC, useState, useEffect } from "react";
import { NotFound } from "../NotFound";
import { User } from "../User";
import { Filters } from "../Filters";
import { InputFocusProvider } from "../Filters/InputFocusContext";
import { TG } from "../../functions/global";
import { useDispatch, useSelector } from "react-redux";
import { TypeRootState } from "../../app/store";
import { usersActions } from "../../app/store/users/users.slice";

export const UserList: FC = () => {
  const dispatch = useDispatch();
  const { storeSearch, storeCompany } = useSelector((state: TypeRootState) => state.users);
  const [users, setUsers] = useState<IUser[]>([
    {
      id: 1,
      company: "Ассоциация \"ИТ-Кластер Сибири\"",
      name: "Международный фестиваль робототехники и инноваций \"ИнноТехОмск 2024\"",
      date: "2-3 марта 2024",
      url: "https://innofest.tech/",
      description: "Фестиваль направлен на поддержку и пропаганду инженерно-технической сферы и робототехники среди молодежи, поэтому особое внимание уделяется мероприятиям, способствующим становлению проектного и инженерного мышления, ранней профессиональной ориентации подрастающего поколения.",
      images: ["./3.jpg"]
    },
    {
      id: 2,
      company: "Ассоциация \"ИТ-Кластер Сибири\"",
      name: "X Международный ИТ-Форум Омск-2025",
      date: "4-6 марта 2024",
      url: "https://oiitf.ru/",
      description: "Крупная экспертная площадка для обсуждения передовых мировых и российских разработок высокотехнологичного характера: от технологий до их практической реализации",
      images: ["./4.jpg"]
    },
    {
      id: 3,
      company: "Сообщество цифровых управленцев \"я-ИТ-ы\"",
      name: "Экскурсию в филиал акционерного общества «Объединенная двигателестроительная корпорация» «Омское моторостроительное объединение им. П.И. Баранова»",
      date: "18 октября 2024",
      url: "https://ciocdo.ru/meropriyatiya/166139/",
      description: "В рамках экскурсии будет представлено подробное описание производственного процесса по производству лопаток, дисков и узлов авиационных двигателей. Включая современные технологии по глубинному шлифованию лопаток, многофункциональное оборудование 4 и 5 поколения, а также уровень автоматизации, применяемые на заводе. Мы уверены, что данная экскурсия будет полезна для расширения профессиональных знаний и понимания актуальных тенденций в области авиастроения.",
      images: ["./1.jpg"]
    },
    {
      id: 4,
      company: "АПКИТ",
      name: "Круглый стол в Думе о нарушениях IP",
      date: "14 октября 2024 ",
      url: "https://apkit.ru/news/kruglyy-stol-v-dume-o-narusheniyakh-ip/https://apkit.ru/news/kruglyy-stol-v-dume-o-narusheniyakh-ip/",
      description: "Тема: «Особенности ответственности информационных посредников, классифайдеров и маркетплейсов, за нарушения исключительных прав правообладателей». Причиной беспокойства остается правовая неопределенность в четком понимании оснований освобождения информационного посредника от ответственности за нарушение интеллектуальных прав, в частности,  обязанности сайта объявлений блокировать профили нарушителей-пользователей за неоднократное размещение запрещенных объявлений, введение обязательной предмодерации объявлений, которая бы исключала возможность повторного размещения запрещенных объявлений, а также сроков удаления запрещенных объявлений, в которых неправомерно используются товарные знаки правообладателей.",
      images: ["./2.jpg"]
    }
  ]);

  const [search, setSearch] = useState(storeSearch);
  const [company, setCompany] = useState<IFilters[]>(storeCompany);

  const [filters, setFilters] = useState<IFiltersAll>({
    search: "",
    company: []
  });

  useEffect(() => {
    TG.BackButton.hide();
  }, []);

  // Синхронизация изменений локального состояния с store
  useEffect(() => {
    dispatch(usersActions.setSearch(search));
  }, [search, dispatch]);

  // Фильтрация пользователей по введенному поисковому запросу и выбранным компаниям
  const filteredUsers = users.filter((user) => {
    const matchesSearch = search
      ? user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.description.toLowerCase().includes(search.toLowerCase())
      : true;
    const matchesCompany = company.length
      ? company.some((filter) => filter.value === user.company)
      : true;

    return matchesSearch && matchesCompany;
  });

  return (
    <div>
      <InputFocusProvider>
        <Filters search={search} setSearch={setSearch} company={company} setCompany={setCompany} filters={filters} />
        <div className={styles.users_list}>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => (
            <User key={user.id} user={user} />
          ))
        ) : (
          <NotFound />
        )}
        </div>
      </InputFocusProvider>
    </div>
  );
};
