import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Avatar.module.scss";
import { ReactComponent as NoAvatarIcon } from "../../assets/icons/noavatar.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

interface AvatarProps {
  images: string | string[];
  loader?: boolean;
  gallery?: boolean;
}

export const Avatar: FC<AvatarProps> = ({ images, loader, gallery }) => {
  const parseImages = (images: string | string[]): string[] => {
    if (typeof images === "string") {
      try {
        const parsed = JSON.parse(images);
        return Array.isArray(parsed) ? parsed : [];
      } catch {
        return [];
      }
    } else if (Array.isArray(images)) {
      return images.filter(s=>typeof s === "string");
    } else {
      return [];
    }
  };

  const imagesArray = parseImages(images); // Преобразуем к массиву
  const ref = useRef<HTMLDivElement>(null);
  const [avatarStyle, setAvatarStyle] = useState({ height: 'auto' });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageError, setImageError] = useState<boolean[]>(Array(imagesArray.length).fill(false));

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setAvatarStyle({ height: ref.current ? `${ref.current.offsetWidth / 1.5}px` : 'auto' });
  }, [windowWidth]);

  const handleImageError = (index: number) => {
    setImageError((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });
  };

  const ErrorContent = () => (
      <div className={styles.not_avatar}><NoAvatarIcon /></div>
  );
  return (
      <div className={styles.avatar} ref={ref} >
        {imagesArray.length === 0 ? (
            <ErrorContent />
        ) : imagesArray.length > 1 && gallery ? (
            <Swiper
              className={styles.swiper}
              spaceBetween={10}
              slidesPerView={1}
              modules={[Pagination]}
              pagination={{ clickable: true, type: 'bullets', horizontalClass: styles.pagination }}
            >
              {imagesArray.map((url: string, index: number) => (
                <SwiperSlide key={index}>
                  {imageError[index] ? (
                    <ErrorContent/>
                  ) : (
                        <img
                            src={url}
                            alt={`avatar-${index}`}
                            onError={() => handleImageError(index)}
                        />
                    )}
                  </SwiperSlide>
              ))}
            </Swiper>
        ) : (
            imageError[0] ? (
                <ErrorContent />
            ) : (
                <img
                    src={imagesArray[0]}
                    alt='avatar'
                    onError={() => {
                      handleImageError(0);
                    }}
                />
            )
        )}
      </div>
  );
};
