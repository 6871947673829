import styles from './User.module.scss';
import { Link } from "react-router-dom";
import React, { FC } from "react";
import { IUser } from "../../app/store/users/user.type";
import { Avatar } from "../Avatar";

export const User: FC<{ user: IUser }> = ({ user }) => {
    return (
      <div className={styles.card}>
          <Link to={`/${user.id}`} state={{ user }}>
              <div className={styles.content}>
                  <div className={styles.info}>
                      <Avatar images={user.images} />
                      <div className={styles.name}>{user.name}</div>
                      <div className={styles.item}>{user.date}</div>
                  </div>
                <div className={styles.button}>Подробнее</div>
              </div>
          </Link>
      </div>
    );
};

