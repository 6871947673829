import styles from './Filters.module.scss'
import '../../select.scss'
import { MultiSelect } from "react-multi-select-component"
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg"
import { ReactComponent as FiltersIcon } from "../../assets/icons/filters.svg"
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import React, { FC, useEffect, useRef, useState } from "react"
import { useInputFocus } from "./InputFocusContext"
import { IFilters, IFiltersAll } from "../../app/store/users/user.type"

interface FiltersProps {
	search: string,
	setSearch: (value: string) => void,
	company: IFilters[],
	setCompany: (company: IFilters[]) => void,
	filters: IFiltersAll
}

export const Filters: FC<FiltersProps> = ({ search, setSearch, company, setCompany, filters }) => {
	const [rightBar, setRightBar] = useState<{ visible: boolean, right: string }>({ visible: false, right: "-100%" });
	const { inputRef } = useInputFocus();
	const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, [search]);

	const handleSearchChange = (value: string) => {
		setSearch(value);
		if (searchTimeoutRef.current) {
			clearTimeout(searchTimeoutRef.current);
		}
		searchTimeoutRef.current = setTimeout(() => {
			setSearch(value);
		}, 500);
	};

	return (
		<>
			<div className={styles.filters}>
				<label className={styles.search}>
					<input
						type='text'
						placeholder='Поиск'
						value={search}
						onChange={(e) => handleSearchChange(e.target.value)}
						ref={inputRef}
					/>
					<SearchIcon />
				</label>
				<div className={styles.trigger} onClick={() => setRightBar({ visible: true, right: "0" })}>
					<FiltersIcon />
				</div>
			</div>
			<div
				className={styles.overlay}
				onClick={() => setRightBar({ visible: false, right: "-100%" })}
				style={rightBar.visible ? { display: "block" } : { display: "none" }}
			/>
			<div className={`${styles.wrapper} ${rightBar.visible ? styles.show : ''}`}>
				<div className={styles.form} style={{ right: rightBar.right }}>
					<div className={styles.container}>
						<div className={styles.header}>
							<div className={styles.title}>
								<FiltersIcon /> Фильтр
							</div>
							<CloseIcon onClick={() => setRightBar({ visible: false, right: "-100%" })} />
						</div>
						<MultiSelect
							options={filters.company}
							value={company}
							onChange={setCompany}
							labelledBy="Выберите компанию"
							className="select"
							ClearSelectedIcon={null}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
