import { useLocation, useNavigate } from 'react-router-dom';
import { IUser } from "../../app/store/users/user.type";
import { isValidUrl, TG } from "../../functions/global";
import styles from "./UserProfile.module.scss";
import React, { useEffect } from "react";
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import { Avatar } from "../Avatar";

export const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Явное приведение состояния к нужному типу
  const user = (location.state as { user: IUser })?.user;

  useEffect(() => {
    window.scrollTo(0, 0);
    TG.BackButton.show();
    const handleBackButtonClick = () => {
      navigate('/');
    };
    if (!user) handleBackButtonClick();
    TG.onEvent('backButtonClicked', handleBackButtonClick);
    return () => {
      TG.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar images={[...user.images]} gallery={true}/>
      </div>
      <div className={styles.name}>{user.name}</div>
      <div className={styles.title}>{user.date}</div>
      <div className={styles.info}>
        <div className={styles.items}>
          <div className={styles.item}><b>{user.company}</b></div>
          <div className={styles.item}>{user.description}</div>
        </div>
      </div>
      {user.url && (
        <div className={styles.link}>
          <LinkIcon/>
          <a href={isValidUrl(user.url) ? user.url : 'http://' + user.url} target="_blank" rel="noopener noreferrer">
            {user.url}
          </a>
        </div>
      )}
    </div>
  );
};
